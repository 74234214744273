<template>
    <section class="m-4">
        <div class="anhor" id="format"></div>
        <div class="wrapper">
            <h2 class="section-title">O'quv Dastur Formati </h2>
            <div class="m-4-flex">
                <div class="m-4-item m-4-item--1 active wow fadeInUp">
                    <div class="m-4-item-hover-box">
                        <div class="m-4-item-icon">
                            <img width="68" height="68"
                                src="https://www.sanchotrader.com/wp-content/uploads/2024/06/icon.svg-2024-06-05t135215.350-1.svg"
                                class="" alt="" decoding="async">
                        </div>
                        <div class="m-4-item-hover-title"> Uslubiy materiallar </div>
                        <div class="m-4-item-hover-text">
                            <p>Savdo uchun zarur bo'lgan barcha vositalarning tuzilgan umumiy
                                <strong> materiallar.</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="m-4-item m-4-item--2  wow fadeInUp">
                    <div class="m-4-item-hover-box">
                        <div class="m-4-item-icon">
                            <img width="68" height="68"
                                src="https://www.sanchotrader.com/wp-content/uploads/2024/06/frame-3.svg" class=""
                                alt="" decoding="async">
                        </div>
                        <div class="m-4-item-hover-title">Video darslar </div>
                        <div class="m-4-item-hover-text">
                            <p>Video darslarda har bir mavzu nazariy va amaliyotda batafsil tahlil qilingan, va
                                <strong>materialni chuqur o‘zlashtirish </strong> va uni samarali qo‘llash imkonini
                                beradi.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="m-4-item m-4-item--3  wow fadeInUp">
                    <div class="m-4-item-hover-box">
                        <div class="m-4-item-icon">
                            <img width="68" height="68"
                                src="https://www.sanchotrader.com/wp-content/uploads/2024/06/frame-4.svg" class=""
                                alt="" decoding="async">
                        </div>
                        <div class="m-4-item-hover-title"> Onlayn konferensiya </div>
                        <div class="m-4-item-hover-text">
                            <p>Onlayn konferentsiyalar dars mavzularini qo'shimcha o'rganish, uy vazifalarini tekshirish
                                va <strong>savdoni tahlil qilishni </strong>o'z ichiga oladi.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="m-4-item m-4-item--4  wow fadeInUp">
                    <div class="m-4-item-hover-box">
                        <div class="m-4-item-icon">
                            <img width="66" height="66"
                                src="https://www.sanchotrader.com/wp-content/uploads/2024/06/group.svg" class="" alt=""
                                decoding="async">
                        </div>
                        <div class="m-4-item-hover-title"> Community </div>
                        <div class="m-4-item-hover-text">
                            <p>Treyderlar guruhi tajriba almashish va yangi <strong>bilimlarni mustahkamlash</strong>
                                uchun qulay muhit yaratadi.</p>
                        </div>
                    </div>
                </div>
                <div class="m-4-item m-4-item--5  wow fadeInUp">
                    <div class="m-4-item-hover-box">
                        <div class="m-4-item-icon">
                            <img width="68" height="68"
                                src="https://www.sanchotrader.com/wp-content/uploads/2024/06/frame-5.svg" class=""
                                alt="" decoding="async">
                        </div>
                        <div class="m-4-item-hover-title">Cheksiz kirish </div>
                        <div class="m-4-item-hover-text">
                            <p>Matn va video materiallardan doimiy foydalanish ishtirokchilarga nazariyani istalgan
                                qulay vaqtda o‘zlashtirish va o‘tilgan mavzularni <strong>tizimli ravishda
                                    takrorlash</strong>
                                imkoniyatini beradi.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="m-4-item m-4-item--6  wow fadeInUp">
                    <div class="m-4-item-hover-box">
                        <div class="m-4-item-icon">
                            <img width="68" height="68"
                                src="https://www.sanchotrader.com/wp-content/uploads/2024/06/frame-6.svg" class=""
                                alt="" decoding="async">
                        </div>
                        <div class="m-4-item-hover-title">Qayta aloqa </div>
                        <div class="m-4-item-hover-text">
                            <p>O'quv jarayonida tajribali murabbiylarning tavsiyalari va yordami yuzaga kelgan
                                <strong> muammolarni samarali hal qilishga </strong> yordam beradi.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script setup>
</script>

<style scoped lang="scss"></style>
