<template>
    <section class="m-6">
        <div class="m-6-bg-left"></div>
        <div class="m-6-bg-right"></div>
        <div class="wrapper">
            <h2 class="section-title-2 wow fadeInUp">
                <div class="section-title-2-dots"> </div>
                <span>Kasbdagi Halol va Harom </span>
            </h2>
            <div class="m-6-after-title">Maqsad boy bo'lish emas aksincha halol kasb qilish.</div>
        </div>
    </section>
</template>



<script setup>
</script>

<style scoped lang="scss"></style>
