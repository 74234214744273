<template>
  <footer class="main-footer">
    <div class="wrapper">
      <div class="main-footer-flex">
        <div class="main-footer-left">
          <div class="main-footer-slogan">Doimiy o'rganing, cheksiz o'sing</div>
        </div>
        <div class="main-footer-right">
          <div class="main-footer-right-column">
            <div class="main-footer-right-title">Ta'lim</div>
            <div class="main-footer-riht-menu">
              <ul>
                <li>
                  <a href="/#format">Kurs formati</a>
                </li>
                <li>
                  <a href="/#program">Kurs dasturi</a>
                </li>
                <li>
                  <a href="/#price">Obuna turlari</a>
                </li>
                <li>
                  <a href="/#reference">Ma'lumot </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="main-footer-right-column">
            <div class="main-footer-right-title">Media</div>
            <div class="main-footer-riht-menu">
              <ul>
                <li>
                  <a href="https://t.me/abutradiing" target="_blank"
                    >Telegram</a
                  >
                </li>
                <li>
                  <a href="https://youtube.com/" target="_blank">Youtube</a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/abutrading?igsh=MWlsNTdtYzNpb2JxOQ=="
                    target="_blank"
                    >Instagram</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="main-footer-right-column">
            <div class="main-footer-right-title">Kontaktlar</div>
            <div class="main-footer-riht-menu">
              <ul>
                <li>
                  <a href="https://t.me/abucrypto1" target="_blank">Telegram</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="main-footer-copy">
        ©abutrading.io, 2024 г. Все права защищены.
      </div>
    </div>
  </footer>
</template>


<script setup>
</script>

<style scoped lang="scss">
.main-footer {
  padding-top: 60px;
  border-top: 1px solid #2a2727;
}
</style>
