<template>
    <div class="mobile-header-bg"></div>
    <div class="mobile-header">
        <div class="main-menu">
            <ul id="menu-glavnoe-menyu-1" class="menu nav_main">
                <li
                    class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-111">
                    <a href="/#format" aria-current="page">Формат</a>
                </li>
                <li
                    class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-112">
                    <a href="/#program" aria-current="page">Программа</a>
                </li>
                <li
                    class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-113">
                    <a href="/#price" aria-current="page">Тарифы</a>
                </li>
                <li
                    class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-114">
                    <a href="/#reference" aria-current="page">Справка</a>
                </li>
                <li
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1090">
                    <a href="#">Калькулятор</a>
                    <ul class="sub-menu">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1061">
                            <a href="https://www.sanchotrader.com/calculator/">Crypto</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1091">
                            <a href="https://www.sanchotrader.com/calculator-forex/">Forex</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="main-header-person">
            <a href="https://www.sanchotrader.com/authorization/" class="js-login active">Вход </a>
        </div>
    </div>
</template>


<script setup>
</script>

<style scoped lang="scss"></style>
