<template>
    <marquee direction="left" class="marquee">⚡️ Kriptovalyutalar bilan foydalanayotganlar soni 400,000,000
        foydalanuvchi soniga yetdi. ⚡️ Kriptovalyutalar bilan foydalanayotganlar soni 400,000,000 foydalanuvchi soniga
        yetdi. ⚡️ Nigeriyada milliy valyuta nayra qadrsizlanayotgani sabab, kriptovalyuta qidiruvini berish bo'yicha
        birinchi o'ringa chiqdi. ⚡️ Salvador prezidenti: Bizning keyingi dasturimiz, Bitcoin va kriptovalyutaga
        investitsiya uchun fuqarolikni taklif etish
    </marquee>
</template>


<script setup>
</script>

<style scoped lang="scss">
.marquee {
    color: #fff;
    font-size: 26px;
    padding: 10px 0;
    background: radial-gradient(circle, rgba(0, 11, 64, 0.123) 0%, rgba(0, 0, 0, 0.255) 100%);
}
</style>
